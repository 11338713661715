type ResponsiveSizes = "sm" | "md" | "lg" | "xl" | "2xl";
type Variants = { [key: string]: string };

export function responsiveVariants<
  T extends Variants,
  K extends ResponsiveSizes,
>(size: K, variants: T) {
  const newVariants: Partial<
    Record<`${K}:${Extract<keyof T, string>}`, string>
  > = {};
  for (const key in variants) {
    const variantClasses = variants[key];
    newVariants[`${size}:${key}`] = variantClasses
      .split(" ")
      .map((cls) => `${size}:${cls}`)
      .join(" ");
  }
  return newVariants;
}
